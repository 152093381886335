import axios from 'axios';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SellersService } from 'app/providers/api/services';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import { snakeCase } from 'shared/utils/converRequestAndresponseData';

const useSellerPolicyInfo = () => {
  const { sellerId } = useParams();
  const [policiesData, setPoliciesData] = useState(null);
  const [isUploadData, setIsUploadData] = useState(false);

  const { mutateAsync: fetchPolicyUploadUrl } = useApiMutation(
    ['getSellerPoliciesUploadUrl', sellerId],
    () => SellersService.fetchSellerPoliciesUploadUrl(sellerId),
    {
      onError: () => {
        setIsUploadData(false);
      },
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingPolicies, refetch: refetchPolicies } =
    useApiQuery(
      ['getSellerPolicies', sellerId],
      () => SellersService.fetchSellerPolicies(sellerId),
      {
        onSuccess: (data) => {
          setPoliciesData(data);
          setIsUploadData(false);
        },
        onError: () => {
          setIsUploadData(false);
        },
        cacheTime: 0,
      },
    );

  const { mutateAsync: uploadSellerPoliciesLink } = useApiMutation(
    ['uploadSellerPoliciesLink', sellerId],
    SellersService.uploadSellerPoliciesLink,
    {
      onError: () => {
        setIsUploadData(false);
      },
    },
  );

  const handleSubmitPoliciesLink = useCallback(
    async (link: string, policyType: string) => {
      setIsUploadData(true);
      await uploadSellerPoliciesLink({
        idOfSeller: sellerId,
        data: {
          link,
          policyType: snakeCase(policyType),
        },
      });
      await refetchPolicies();
    },
    [uploadSellerPoliciesLink, sellerId, refetchPolicies],
  );

  const handleSubmitPoliciesPdf = useCallback(
    async (file: File, policyType: string) => {
      setIsUploadData(true);
      if (file) {
        const { url } = await fetchPolicyUploadUrl(sellerId);
        const urlFromS3 = url.split('?')[0].replace('s3.amazonaws.com/', '');
        await axios.put(url, file, {
          headers: {
            'Content-Type': 'application/pdf',
          },
        });
        await handleSubmitPoliciesLink(urlFromS3, policyType);
        await refetchPolicies();
      }
    },
    [handleSubmitPoliciesLink, refetchPolicies, fetchPolicyUploadUrl, sellerId],
  );

  const isLoadingPolicyTab = isLoadingPolicies || isUploadData;

  return {
    handleSubmitPoliciesPdf,
    handleSubmitPoliciesLink,
    policiesData,
    sellerId,
    isLoadingPolicies,
    isLoadingPolicyTab,
  };
};

export default useSellerPolicyInfo;
