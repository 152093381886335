import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { SellersService } from 'app/providers/api';
import { sellerApiAuthTypes } from 'shared/constants/constants';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import { SellerApiConfig } from 'shared/types/Sellers';
import { Loader } from 'shared/ui/Loader';
import { TextFieldPasswordVisibilityToggle } from 'shared/ui/TextFieldPasswordVisibilityToggle';

import { SellerApiConfigFormSkeleton } from './SellerApiConfigLoadingSkeleton';
import { SellerApiConfigLoadingStatus } from './SellerApiConfigLoadingStatus';

import {
  SellerApiConfigFormValues,
  getSellerApiConfigFormValidationSchema,
  populateConfigFromFormValues,
  populateFormValuesFromConfig,
  sellerApiConfigFormDefaultValues,
} from '../utils/SellerApiConfigFormUtils';

interface SellerApiConfigFormProps {
  sellerId: string;
}

const SellerApiConfigForm = ({ sellerId }: SellerApiConfigFormProps) => {
  const { t } = useTranslation();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);

  const [apiState, setApiState] = useState<{
    savedApiConfig: SellerApiConfig;
    initialValues: SellerApiConfigFormValues | null;
    hasLoaded: boolean;
  }>({
    savedApiConfig: null,
    initialValues: null,
    hasLoaded: false,
  });

  const validationSchema = useMemo(
    () => getSellerApiConfigFormValidationSchema(t),
    [t],
  );

  // Load the Seller API Config and hydrate the form initial values
  // Do not that if this 404s, that's okay... it just means the seller doesn't have an API config yet
  const { isLoading } = useApiQuery(
    ['fetchSellerApiConfig', sellerId],
    () => SellersService.fetchSellerApiConfig(sellerId),
    {
      onSuccess: (data) => {
        setApiState({
          savedApiConfig: data,
          initialValues: populateFormValuesFromConfig(data),
          hasLoaded: true,
        });
      },
      shouldHandleError: (error) => {
        if (error?.response?.status === 404) {
          setApiState({
            savedApiConfig: null,
            initialValues: sellerApiConfigFormDefaultValues,
            hasLoaded: true,
          });
          return false;
        }
        return true;
      },
      cacheTime: 0,
    },
  );

  // Create and update just use post and patch, but same payload
  const { mutate: updateSellerApiConfig, isLoading: isUpdating } =
    useApiMutation(
      ['updateSellerApiConfig', sellerId],
      (values: SellerApiConfig) =>
        SellersService.updateSellerApiConfig(sellerId, values),
      {
        onSuccess: (data) => {
          toast.success(t('Changes saved successfully'));
          setSubmitAttempted(false);
          setApiState({
            savedApiConfig: data,
            initialValues: populateFormValuesFromConfig(data),
            hasLoaded: true,
          });
        },
      },
    );

  const { mutate: createSellerApiConfig, isLoading: isCreating } =
    useApiMutation(
      ['createSellerApiConfig', sellerId],
      (values: SellerApiConfig) =>
        SellersService.createSellerApiConfig(sellerId, values),
      {
        onSuccess: (data) => {
          toast.success(t('Changes saved successfully'));
          setSubmitAttempted(false);
          setApiState({
            savedApiConfig: data,
            initialValues: populateFormValuesFromConfig(data),
            hasLoaded: true,
          });
        },
      },
    );

  const handleSubmit = (values: SellerApiConfigFormValues) => {
    const configToSave = populateConfigFromFormValues(values);
    if (apiState.savedApiConfig) {
      updateSellerApiConfig(configToSave);
    } else {
      createSellerApiConfig(configToSave);
    }
  };

  const processedAtDate = apiState.savedApiConfig?.processedAt
    ? new Date(apiState.savedApiConfig.processedAt)
    : null;

  const updatedAtDate = apiState.savedApiConfig?.updatedAt
    ? new Date(apiState.savedApiConfig.updatedAt)
    : null;

  if (isLoading || !apiState.initialValues) {
    return <SellerApiConfigFormSkeleton />;
  }

  return (
    <>
      <Typography
        typography="openSans.body2"
        sx={{
          maxWidth: '750px',
          marginBottom: '30px',
        }}
      >
        {t(
          // eslint-disable-next-line max-len
          "This API and the credentials are used for RE-OPS to authenticate with the seller's API from the Order API.",
        )}
        <br />
        {t(
          'RE-OPS supports a variety of authentication methods for ease of integration.',
        )}
      </Typography>
      <SellerApiConfigLoadingStatus
        isLoading={isLoading}
        hasSavedConfig={!!apiState.savedApiConfig}
        processedAt={processedAtDate}
        updatedAt={updatedAtDate}
      />
      <Formik
        initialValues={apiState.initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          setSubmitAttempted(true);
          handleSubmit(values);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          isValid,
          dirty,
          resetForm,
        }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '750px',
              }}
            >
              <TextField
                name="apiUrl"
                sx={{
                  mb: '30px',
                }}
                label={t('API URL')}
                value={values.apiUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={submitAttempted && Boolean(errors.apiUrl)}
                helperText={submitAttempted && errors.apiUrl}
                fullWidth
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '400px',
                }}
              >
                <FormControl fullWidth sx={{ mb: '30px' }}>
                  <InputLabel>{t('Authentication Type')}</InputLabel>
                  <Select
                    name="authType"
                    label={t('Authentication Type')}
                    value={values.authType}
                    onChange={handleChange}
                    fullWidth
                    error={submitAttempted && Boolean(errors.authType)}
                  >
                    {sellerApiAuthTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography
                  component="h4"
                  typography="poppins.h4"
                  sx={{ mb: '20px' }}
                >
                  {t('Authentication details')}
                </Typography>

                {(() => {
                  switch (values.authType) {
                    case 'basic':
                      return (
                        <>
                          <TextField
                            name="username"
                            sx={{ mb: '30px' }}
                            label={t('Username')}
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete="off"
                            error={submitAttempted && Boolean(errors.username)}
                            helperText={submitAttempted && errors.username}
                          />
                          <TextField
                            name="password"
                            sx={{ mb: '30px' }}
                            label={t('Password')}
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete="off"
                            error={submitAttempted && Boolean(errors.password)}
                            helperText={submitAttempted && errors.password}
                            InputProps={{
                              endAdornment: (
                                <TextFieldPasswordVisibilityToggle
                                  show={showPassword}
                                  onToggle={() =>
                                    setShowPassword(!showPassword)
                                  }
                                  label={t('Toggle password visibility')}
                                />
                              ),
                            }}
                          />
                        </>
                      );
                    case 'key':
                      return (
                        <TextField
                          name="apiKey"
                          sx={{ mb: '30px' }}
                          label={t('API Key')}
                          multiline
                          rows={3}
                          value={values.apiKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          autoComplete="off"
                          error={submitAttempted && Boolean(errors.apiKey)}
                          helperText={submitAttempted && errors.apiKey}
                        />
                      );
                    case 'jwt':
                      return (
                        <TextField
                          name="jwtKey"
                          sx={{ mb: '30px' }}
                          label={t('JWT Key')}
                          multiline
                          rows={3}
                          value={values.jwtKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          autoComplete="off"
                          error={submitAttempted && Boolean(errors.jwtKey)}
                          helperText={submitAttempted && errors.jwtKey}
                        />
                      );
                    case 'oauth2':
                      return (
                        <>
                          <TextField
                            name="clientId"
                            sx={{ mb: '30px' }}
                            label={t('Client')}
                            value={values.clientId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete="off"
                            error={submitAttempted && Boolean(errors.clientId)}
                            helperText={submitAttempted && errors.clientId}
                          />
                          <TextField
                            name="clientSecret"
                            sx={{ mb: '30px' }}
                            label={t('Secret')}
                            type={showClientSecret ? 'text' : 'password'}
                            value={values.clientSecret}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete="off"
                            error={
                              submitAttempted && Boolean(errors.clientSecret)
                            }
                            helperText={submitAttempted && errors.clientSecret}
                            InputProps={{
                              endAdornment: (
                                <TextFieldPasswordVisibilityToggle
                                  show={showClientSecret}
                                  onToggle={() =>
                                    setShowClientSecret(!showClientSecret)
                                  }
                                  label={t('Toggle secret visibility')}
                                />
                              ),
                            }}
                          />
                        </>
                      );
                    default:
                      return null;
                  }
                })()}

                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: '30px',
                    gap: '20px',
                    justifyContent: 'right',
                    minHeight: '42px',
                  }}
                >
                  <Button
                    color="primary"
                    disabled={isUpdating || isCreating}
                    onClick={() => {
                      resetForm();
                      setSubmitAttempted(false);
                    }}
                    sx={{
                      display: dirty ? 'inline-flex' : 'none',
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    startIcon={
                      (isUpdating || isCreating) && <Loader color="inherit" />
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      (submitAttempted && !isValid) || isUpdating || isCreating
                    }
                    onClick={() => setSubmitAttempted(true)}
                    sx={{
                      display: dirty ? 'inline-flex' : 'none',
                    }}
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { SellerApiConfigForm };
