import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { userRoles } from 'shared/constants/constants';
import { SellerInvitedUser } from 'shared/types/Sellers';
import { EditableDropdown } from 'shared/ui/EditableDropdown';
import { Modal } from 'shared/ui/Modal';

interface EditUserFormValues {
  roles: string[];
}

const validationSchemaInviteUser = Yup.object({
  roles: Yup.array()
    .of(
      Yup.string().oneOf(
        userRoles.map((role) => role.id),
        'Invalid role',
      ),
    )
    .min(1, 'At least one role is required')
    .required('Roles are required'),
});

export const EditUserFormModal = ({
  isOpenEditUserModal,
  user,
  sellerId,
  handleClose,
  sellerEditUser,
}: {
  isOpenEditUserModal: boolean;
  sellerId: string;
  user: SellerInvitedUser;
  handleClose: () => void;
  sellerEditUser: ({
    idOfSeller,
    idOfUser,
    data,
  }: {
    idOfSeller: string;
    idOfUser: string;
    data: {
      roles: string[];
    };
  }) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik<EditUserFormValues>({
    initialValues: {
      roles: [user?.roles[0]],
    },
    enableReinitialize: true,
    validationSchema: validationSchemaInviteUser,
    onSubmit: (values) => {
      sellerEditUser({
        idOfSeller: sellerId,
        idOfUser: user.id,
        data: values,
      });
      handleClose();
    },
  });

  const handleSaveFormikInviteUser = async () => {
    const isValid = await formik.validateForm();
    await formik.setTouched({
      roles: true,
    });

    if (Object.keys(isValid).length === 0) {
      formik.handleSubmit();
    }
  };

  if (!user) return null;

  return (
    <Modal
      isModalOpen={isOpenEditUserModal}
      handleCloseModal={handleClose}
      handleSubmitModal={handleSaveFormikInviteUser}
      approveNameButton={t('Save')}
      disabled={!formik.isValid || !formik.dirty}
    >
      <Typography mb="20px" typography="poppins.subtitle2">
        {t(`Change ${user.firstName} ${user.lastName} role`)}
      </Typography>
      <Box my={2} display="flex" flexDirection="column" gap={3}>
        <EditableDropdown
          field={{
            name: 'roles',
            label: 'Role',
            value: formik.values.roles[0],
            options: userRoles,
          }}
          onChange={async (event) => {
            await formik.setFieldValue('roles', [event.target.value as string]);
            await validationSchemaInviteUser.validateAt('roles', {
              ['roles']: [event.target.value as string],
            });
          }}
        />
      </Box>
    </Modal>
  );
};
