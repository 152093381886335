import * as Yup from 'yup';

import { sellerApiAuthTypes } from 'shared/constants/constants';
import {
  SellerApiConfig,
  SellerApiConfigApiKey,
  SellerApiConfigAuthType,
  SellerApiConfigBasic,
  SellerApiConfigJwt,
  SellerApiConfigOauth2,
} from 'shared/types/Sellers';

export interface SellerApiConfigFormValues {
  apiUrl: string;
  authType: SellerApiConfigAuthType;
  username: string;
  password: string;
  apiKey: string;
  jwtKey: string;
  clientId: string;
  clientSecret: string;
}

export const sellerApiConfigFormDefaultValues: SellerApiConfigFormValues = {
  apiUrl: 'https://',
  authType: 'oauth2',
  username: '',
  password: '',
  apiKey: '',
  jwtKey: '',
  clientId: '',
  clientSecret: '',
};

export const getSellerApiConfigFormValidationSchema = (
  t: (key: string) => string,
) =>
  Yup.object({
    apiUrl: Yup.string()
      .url(t('Invalid API URL.'))
      .required(t('API URL is required.')),
    authType: Yup.string()
      .oneOf(sellerApiAuthTypes.map((opt) => opt.key))
      .required(t('Auth Type is required.')),
    username: Yup.string().when('authType', {
      is: 'basic',
      then: (schema) => schema.required(t('Username is required.')),
      otherwise: (schema) => schema.notRequired(),
    }),
    password: Yup.string().when('authType', {
      is: 'basic',
      then: (schema) => schema.required(t('Password is required.')),
      otherwise: (schema) => schema.notRequired(),
    }),
    apiKey: Yup.string().when('authType', {
      is: 'key',
      then: (schema) => schema.required(t('API Key is required.')),
      otherwise: (schema) => schema.notRequired(),
    }),
    jwtKey: Yup.string().when('authType', {
      is: 'jwt',
      then: (schema) => schema.required(t('JWT Key is required.')),
      otherwise: (schema) => schema.notRequired(),
    }),
    clientId: Yup.string().when('authType', {
      is: 'oauth2',
      then: (schema) => schema.required(t('Client Key is required.')),
      otherwise: (schema) => schema.notRequired(),
    }),
    clientSecret: Yup.string().when('authType', {
      is: 'oauth2',
      then: (schema) => schema.required(t('Client Secret is required.')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export const populateFormValuesFromConfig = (apiConfig: SellerApiConfig) => {
  return {
    apiUrl: apiConfig.baseUrl,
    authType: apiConfig.authType,
    username:
      apiConfig.authType === 'basic'
        ? (apiConfig.authInfo as SellerApiConfigBasic).username
        : '',
    password:
      apiConfig.authType === 'basic'
        ? (apiConfig.authInfo as SellerApiConfigBasic).password
        : '',
    apiKey:
      apiConfig.authType === 'key'
        ? (apiConfig.authInfo as SellerApiConfigApiKey).apiKey
        : '',
    jwtKey:
      apiConfig.authType === 'jwt'
        ? (apiConfig.authInfo as SellerApiConfigJwt).jwtKey
        : '',
    clientId:
      apiConfig.authType === 'oauth2'
        ? (apiConfig.authInfo as SellerApiConfigOauth2).clientId
        : '',
    clientSecret:
      apiConfig.authType === 'oauth2'
        ? (apiConfig.authInfo as SellerApiConfigOauth2).clientSecret
        : '',
  };
};

export const populateConfigFromFormValues = (
  values: SellerApiConfigFormValues,
) => {
  let configToSave: SellerApiConfig = {
    baseUrl: values.apiUrl,
    authType: values.authType,
    authInfo: null,
  };

  switch (values.authType) {
    case 'basic':
      configToSave.authInfo = {
        username: values.username,
        password: values.password,
      };
      break;
    case 'key':
      configToSave.authInfo = {
        apiKey: values.apiKey,
      };
      break;
    case 'jwt':
      configToSave.authInfo = {
        jwtKey: values.jwtKey,
      };
      break;
    case 'oauth2':
      configToSave.authInfo = {
        clientId: values.clientId,
        clientSecret: values.clientSecret,
      };
      break;
    default:
      throw new Error(`Unknown auth type: ${values.authType}`);
  }

  return configToSave;
};
