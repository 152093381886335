import { useParams } from 'react-router-dom';

const useIntegrationConfigPage = () => {
  const { sellerId } = useParams();

  const isLoading = false;

  return {
    sellerId,
    isLoading,
  };
};

export default useIntegrationConfigPage;
