import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SellersService } from 'app/providers/api';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';

const useSellerAdminIntegrationPage = () => {
  const { t } = useTranslation();
  const [orderApiEnabled, setOrderApiEnabled] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { sellerId } = useParams();

  const { refetch } = useApiQuery(
    ['getSeller', sellerId],
    () => SellersService.fetchSeller(sellerId),
    {
      onSuccess: (data) => {
        setOrderApiEnabled(data.entitlements?.isOrderApiEnabled);
        setPageLoading(false);
      },
    },
  );

  const { mutate: handleUpdateEntitlements } = useApiMutation(
    ['updateSellerEntitlements', sellerId],
    SellersService.updateSellerEntitlements,
    {
      onSuccess: (data) => {
        setOrderApiEnabled(data.isOrderApiEnabled);
        refetch();
        toast.success(t('Changes saved successfully'));
      },
    },
  );

  const handleSwitchChange = (isEnabled: boolean) => {
    handleUpdateEntitlements({
      idOfSeller: sellerId,
      entitlements: {
        isOrderApiEnabled: isEnabled,
      },
    });
  };

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (orderApiEnabled && !event.target.checked) {
      setShowConfirmDialog(true);
    } else {
      handleSwitchChange(event.target.checked);
    }
  };

  const handleConfirmDisable = () => {
    handleSwitchChange(false);
    setShowConfirmDialog(false);
  };

  return {
    sellerId,
    pageLoading,
    handleSwitchToggle,
    orderApiEnabled,
    showConfirmDialog,
    handleConfirmDisable,
    setShowConfirmDialog,
  };
};

export default useSellerAdminIntegrationPage;
