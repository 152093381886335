import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import useIntegrationConfigPage from 'pages/IntegrationPage/hooks/useIntegrationConfigPage';
import { OrderApiKeys } from 'shared/ui/OrderApiKeys';
import { SellerApiConfigForm } from 'shared/ui/SellerApiConfigForm';

export const IntegrationConfigPage = memo(() => {
  const { t } = useTranslation();
  const { sellerId } = useIntegrationConfigPage();

  return (
    <Box flex="1">
      <Box mt={1} display="flex" flexDirection="column" height="100%">
        <Typography component="h3" typography="poppins.h3" marginBottom="30px">
          {t('RE-OPS API access')}
        </Typography>
        <Box marginBottom="20px">
          <OrderApiKeys sellerId={sellerId} />
        </Box>
        <Typography component="h3" typography="poppins.h3" marginBottom="30px">
          {t('Seller API')}
        </Typography>
        <SellerApiConfigForm sellerId={sellerId} />
      </Box>
    </Box>
  );
});
