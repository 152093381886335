import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SellerApiConfigLoadingStatusProps {
  isLoading: boolean;
  hasSavedConfig?: boolean;
  updatedAt?: Date;
  processedAt?: Date;
}

const SellerApiConfigLoadingStatus = ({
  isLoading,
  hasSavedConfig = false,
  updatedAt = null,
  processedAt = null,
}: SellerApiConfigLoadingStatusProps) => {
  const { t } = useTranslation();

  const formattedLastUpdated = updatedAt
    ? new Date(updatedAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : null;

  const isActive = processedAt && processedAt >= updatedAt;

  const lastUpdatedLabel = hasSavedConfig
    ? `${t('Last updated:')} ${formattedLastUpdated}`
    : t('Last updated: Never');

  const lastUpdatedDisplayString = isLoading
    ? t('Last updated: Loading...')
    : lastUpdatedLabel;

  const showStatus = hasSavedConfig && !isLoading;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '520px',
        padding: '22px 24px',
        boxSizing: 'border-box',
        mb: '50px',
        borderRadius: '4px',
        border: '1px solid #D9D9D9',
        background: '#FFF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.85)',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '20px',
          }}
        >
          {t('Authentication')}
        </Typography>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.5)',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          {lastUpdatedDisplayString}
        </Typography>
      </Box>
      {showStatus && (
        <Box
          sx={{
            borderRadius: '22px',
            background: isActive ? '#CBF4C9' : '#D9ECFF',
            padding: '6px 12px',
            color: isActive ? '#377963' : '#3F6EB7',
            textAlign: 'center',
            fontSize: '13px',
            fontWeight: '700',
            lineHeight: '16px',
            letterSpacing: '0.4px',
            height: '28px',
          }}
        >
          {isActive ? t('Active') : t('Processing changes')}
        </Box>
      )}
    </Box>
  );
};

export { SellerApiConfigLoadingStatus };
