import { Box, Typography } from '@mui/material';
import { memo } from 'react';

// import useIntegrationOverviewPage from 'pages/IntegrationPage/hooks/useIntegrationOverviewPage';

export const IntegrationOverviewPage = memo(() => {
  // const { sellerId } = useIntegrationOverviewPage();

  // This will be implemented via another ticket

  return (
    <Box flex="1">
      <Box mt={1} display="flex" height="100%">
        <Typography component="h3" typography="poppins.h3">
          {'Overview'}
        </Typography>
      </Box>
    </Box>
  );
});
