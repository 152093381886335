import {
  ContactInfo,
  Seller,
  SellerApiConfig,
  SellerContactInfo,
  SellerEntitlements,
  SellerEntitlementsResponse,
  SellerGeneralInfo,
  SellerInvitedUserResponse,
  SellerMarketingStatesResponse,
  SellerMarketingTagsResponse,
  SellerOrderApiKeys,
  SellerOrderApiMaskedKeys,
  SellerPoliciesResponse,
  SellerPoliciesUploadResponse,
  SellerUsersInviteResponse,
  Sellers,
  SellersMarketingAllTagsResponse,
} from 'shared/types/Sellers';

import { SELLERS_URL } from '../endpoints';
import request from '../request';

export default class SellersService {
  static readonly fetchSellers = () => {
    return request<Sellers>({
      url: SELLERS_URL,
      method: 'get',
    });
  };

  static readonly addSeller = (data: {
    email: string;
    title: string;
    sellerType: string;
  }) => {
    return request<Sellers>({
      url: SELLERS_URL,
      method: 'post',
      data,
    });
  };

  static readonly fetchSeller = (idOfSeller: string) => {
    return request<Seller>({
      url: `${SELLERS_URL}/${idOfSeller}`,
      method: 'get',
    });
  };

  static readonly updateSeller = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    return request<Seller>({
      url: `${SELLERS_URL}/${idOfSeller}`,
      method: 'patch',
      data,
    });
  };

  static readonly fetchSellerGeneralInfo = (idOfSeller: string) => {
    return request<SellerGeneralInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/general-info`,
      method: 'get',
    });
  };

  static readonly fetchSellerContactInfo = (idOfSeller: string) => {
    return request<SellerContactInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/contact-info`,
      method: 'get',
    });
  };

  static readonly updateSellerGeneralInfo = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    return request<SellerGeneralInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/general-info`,
      method: 'patch',
      data,
    });
  };

  static readonly updateSellerContactInfo = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    if (data.type === 'phone') {
      data.value = `+1 ${data.value}`;
    }

    return request<ContactInfo>({
      url: `${SELLERS_URL}/${idOfSeller}/contact-info`,
      method: 'patch',
      data,
    });
  };

  static readonly updateSellerMarketingStates = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: string[];
  }) => {
    return request<SellerMarketingStatesResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/states`,
      method: 'post',
      data: { data },
    });
  };

  static readonly updateSellerMarketingTags = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { title: string }[];
  }) => {
    return request<SellerMarketingTagsResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/tags`,
      method: 'post',
      data: { data },
    });
  };

  static readonly fetchSellersMarketingAllTags = () => {
    return request<SellersMarketingAllTagsResponse>({
      url: `${SELLERS_URL}/marketing-info/tags`,
      method: 'get',
    });
  };

  static readonly fetchSellerMarketingTags = (idOfSeller: string) => {
    return request<SellerMarketingTagsResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/tags`,
      method: 'get',
    });
  };

  static readonly fetchSellerMarketingStates = (idOfSeller: string) => {
    return request<SellerMarketingStatesResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/marketing-info/states`,
      method: 'get',
    });
  };

  static readonly sellerSendInviteUsers = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: {
      firstName: string;
      lastName: string;
      email: string;
      phone?: string;
      roles: string[];
    };
  }) => {
    data.phone = data.phone ? `+1 ${data.phone}` : null;

    return request<SellerUsersInviteResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/users/invite`,
      method: 'post',
      data,
    });
  };

  static readonly sellerEditUser = ({
    idOfSeller,
    idOfUser,
    data,
  }: {
    idOfSeller: string;
    idOfUser: string;
    data: {
      roles: string[];
    };
  }) => {
    return request<SellerUsersInviteResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/users/${idOfUser}`,
      method: 'patch',
      data,
    });
  };

  static readonly fetchSellerInvitedUsers = (idOfSeller: string) => {
    return request<SellerInvitedUserResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/users`,
      method: 'get',
    });
  };

  static readonly fetchSellerPoliciesUploadUrl = (idOfSeller: string) => {
    return request<{ url: string }>({
      url: `${SELLERS_URL}/${idOfSeller}/upload-url`,
      method: 'get',
    });
  };

  static readonly updateSellerEntitlements = ({
    idOfSeller,
    entitlements,
  }: {
    idOfSeller: string;
    entitlements: SellerEntitlements;
  }) => {
    return request<SellerEntitlementsResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/entitlements`,
      method: 'patch',
      data: entitlements,
    });
  };

  static readonly fetchSellerPolicies = (idOfSeller: string) => {
    return request<SellerPoliciesResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/policies`,
      method: 'get',
    });
  };

  static readonly generateSellerOrderApiKeys = (idOfSeller: string) => {
    return request<SellerOrderApiKeys>({
      url: `${SELLERS_URL}/${idOfSeller}/keys`,
      method: 'post',
    });
  };

  static readonly fetchSellerOrderApiKeys = (idOfSeller: string) => {
    return request<SellerOrderApiMaskedKeys>({
      url: `${SELLERS_URL}/${idOfSeller}/keys`,
      method: 'get',
    });
  };

  static readonly fetchSellerApiConfig = (idOfSeller: string) => {
    return request<SellerApiConfig>({
      url: `${SELLERS_URL}/${idOfSeller}/api-config`,
      method: 'get',
    });
  };

  static readonly createSellerApiConfig = (
    idOfSeller: string,
    data: SellerApiConfig,
  ) => {
    return request<SellerApiConfig>({
      url: `${SELLERS_URL}/${idOfSeller}/api-config`,
      method: 'post',
      data,
    });
  };

  static readonly updateSellerApiConfig = (
    idOfSeller: string,
    data: SellerApiConfig,
  ) => {
    return request<SellerApiConfig>({
      url: `${SELLERS_URL}/${idOfSeller}/api-config`,
      method: 'patch',
      data,
    });
  };

  static readonly uploadSellerPoliciesLink = ({
    idOfSeller,
    data,
  }: {
    idOfSeller: string;
    data: { [key: string]: string };
  }) => {
    return request<SellerPoliciesUploadResponse>({
      url: `${SELLERS_URL}/${idOfSeller}/policies`,
      method: 'patch',
      data,
    });
  };
}
