import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

interface TextFieldPasswordVisibilityToggleProps {
  show: boolean;
  onToggle: () => void;
  label?: string;
}

// Designed to be used as an InputAdornment on a TextField
export const TextFieldPasswordVisibilityToggle = ({
  show,
  onToggle,
  label,
}: TextFieldPasswordVisibilityToggleProps) => {
  return (
    <InputAdornment position="end">
      <IconButton
        title={label}
        aria-label={label}
        onClick={onToggle}
        edge="end"
        sx={{ marginRight: '1px' }}
      >
        {show ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};
