import { Box, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SellerApiConfigLoadingStatus } from './SellerApiConfigLoadingStatus';

const SellerApiConfigFormSkeleton = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        typography="openSans.body2"
        sx={{
          maxWidth: '750px',
          marginBottom: '30px',
        }}
      >
        {t(
          // eslint-disable-next-line max-len
          "This API and the credentials are used for RE-OPS to authenticate with the seller's API from the Order API.",
        )}
        <br />
        {t(
          'RE-OPS supports a variety of authentication methods for ease of integration.',
        )}
      </Typography>
      <SellerApiConfigLoadingStatus isLoading={true} />
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '750px' }}>
        <Skeleton variant="rectangular" height="56px" sx={{ mb: '30px' }} />
        <Skeleton
          variant="rectangular"
          height="56px"
          width="400px"
          sx={{ mb: '30px' }}
        />
        <Typography component="h4" typography="poppins.h4" sx={{ mb: '20px' }}>
          {t('Authentication details')}
        </Typography>
        <Skeleton
          variant="rectangular"
          height="56px"
          width="400px"
          sx={{ mb: '30px' }}
        />
        <Skeleton
          variant="rectangular"
          height="56px"
          width="400px"
          sx={{ mb: '30px' }}
        />
      </Box>
    </>
  );
};

export { SellerApiConfigFormSkeleton };
