import { Alert, Box, Button, Collapse, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SellersService } from 'app/providers/api';
import { useApiMutation } from 'shared/hooks/useApiMutation';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import { CopyableInput } from 'shared/ui/CopyableInput';

interface OrderApiKeysProps {
  sellerId: string;
  fromAdmin?: boolean;
}

export const OrderApiKeys = ({
  sellerId,
  fromAdmin = false,
}: OrderApiKeysProps) => {
  const { t } = useTranslation();

  const [apiState, setApiState] = useState<{
    clientId: string;
    secretKey: string;
    hasData: boolean;
    hasLoaded: boolean;
    isMasked: boolean;
  }>({
    clientId: '',
    secretKey: '',
    hasData: false,
    hasLoaded: false,
    isMasked: true,
  });

  const domain = window.location.hostname;
  const inboundApiUrl =
    domain === 'localhost'
      ? 'http://localhost:8003'
      : `https://inbound-api.${domain.replace('seller.', '')}`;

  useApiQuery(
    ['fetchSellerOrderApiKeys', sellerId],
    () => SellersService.fetchSellerOrderApiKeys(sellerId),
    {
      onSuccess: (data) => {
        setApiState({
          clientId: data.clientId,
          secretKey: data.maskedSecret,
          hasData: true,
          hasLoaded: true,
          isMasked: true,
        });
      },
      shouldHandleError: (error) => {
        if (error.response.status === 404) {
          setApiState({
            clientId: '',
            secretKey: '',
            hasData: false,
            hasLoaded: true,
            isMasked: true,
          });
          return false;
        }
        return true;
      },
      cacheTime: 0,
    },
  );

  const { mutate: handleGenerateApiKeys } = useApiMutation(
    ['generateSellerOrderApiKeys', sellerId],
    () => SellersService.generateSellerOrderApiKeys(sellerId),
    {
      onSuccess: (data) => {
        setApiState({
          clientId: data.clientId,
          secretKey: data.clientSecret,
          hasData: true,
          hasLoaded: true,
          isMasked: false,
        });
      },
    },
  );

  let helpText = t(
    // eslint-disable-next-line max-len
    'These are your credentials to authenticate with the RE-OPS API. Once you generate or regenerate the credentials, you will need to copy the secret key as you will not be able to see it again.',
  );

  if (fromAdmin) {
    helpText = t(
      // eslint-disable-next-line max-len
      "These are the seller's credentials to authenticate with the RE-OPS API. You should only generate or regenerate the credentials if specifically requested to by the seller as they will not be able to see the secret key again.",
    );
  }

  return (
    <>
      <Typography
        typography="openSans.body2"
        sx={{
          maxWidth: '750px',
          marginBottom: '30px',
        }}
      >
        {helpText}
      </Typography>
      <CopyableInput
        label={t('API URL')}
        value={inboundApiUrl}
        maxWidth="750px"
        loading={!apiState.hasLoaded}
      />
      <CopyableInput
        label={t('Client ID')}
        placeholder={t('Not available')}
        value={apiState.clientId}
        maxWidth="500px"
        loading={!apiState.hasLoaded}
      />
      <Box display="flex" gap="20px" alignItems="end">
        <CopyableInput
          label={t('Client Secret Key')}
          placeholder={t('Press Generate to get Client ID and Secret')}
          value={apiState.secretKey}
          maxWidth="500px"
          loading={!apiState.hasLoaded}
          disableCopy={apiState.isMasked}
        />
        {apiState.hasLoaded && (
          <Button
            color="primary"
            sx={{ marginBottom: '37px', width: '140px' }}
            onClick={handleGenerateApiKeys}
          >
            {apiState.hasData ? t('Regenerate') : t('Generate')}
          </Button>
        )}
      </Box>
      <Collapse in={!apiState.isMasked}>
        <Alert severity="info" sx={{ width: '660px', marginBottom: '30px' }}>
          {t(
            "Make sure to copy your Client Secret Key now. You won't be able to see it again!",
          )}
        </Alert>
      </Collapse>
    </>
  );
};
