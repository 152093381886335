import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import useSellerAdminIntegrationPage from 'pages/SellerPage/hooks/useSellerAdminIntegrationPage';
import { Loader } from 'shared/ui/Loader';
import { Modal } from 'shared/ui/Modal';
import { OrderApiKeys } from 'shared/ui/OrderApiKeys';
import { SellerApiConfigForm } from 'shared/ui/SellerApiConfigForm';

export const AdminIntegrationPage = memo(() => {
  const { t } = useTranslation();
  const {
    sellerId,
    pageLoading,
    orderApiEnabled,
    handleSwitchToggle,
    showConfirmDialog,
    handleConfirmDisable,
    setShowConfirmDialog,
  } = useSellerAdminIntegrationPage();

  return (
    <Box flex="1" paddingTop="50px">
      <Box mt={1} display="flex" flexDirection="column" height="100%">
        {!pageLoading ? (
          <Box>
            <Typography
              component="h3"
              typography="poppins.h3"
              marginBottom="30px"
            >
              {t('Order API Access')}
            </Typography>
            <Box marginBottom="30px">
              <FormControlLabel
                control={
                  <Switch
                    checked={orderApiEnabled}
                    onChange={handleSwitchToggle}
                  />
                }
                label={t('Enable Order API')}
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
              />
              <Typography
                typography="openSans.body2"
                sx={{
                  maxWidth: '750px',
                  marginTop: '20px',
                }}
              >
                {t(
                  // eslint-disable-next-line max-len
                  'When enabled, the seller is able to access the Integrations section in the Seller Portal. Once the seller has logged in, they can generate credentials for our API and provide credentials for their Seller API.',
                )}
              </Typography>
            </Box>
            {orderApiEnabled && (
              <>
                <Typography
                  component="h3"
                  typography="poppins.h3"
                  marginBottom="30px"
                >
                  {t('RE-OPS API Credentials')}
                </Typography>
                <Box marginBottom="20px">
                  <OrderApiKeys sellerId={sellerId} fromAdmin={true} />
                </Box>
                <Typography
                  component="h3"
                  typography="poppins.h3"
                  marginBottom="30px"
                >
                  {t('Seller API Credentials')}
                </Typography>
                <SellerApiConfigForm sellerId={sellerId} />
              </>
            )}

            <Modal
              isModalOpen={showConfirmDialog}
              handleCloseModal={() => setShowConfirmDialog(false)}
              handleSubmitModal={handleConfirmDisable}
              approveNameButton={t('Disable Access')}
              approveButtonColor="error"
            >
              <Box sx={{ width: 400 }}>
                <Typography
                  sx={{
                    fontFamily: 'OpenSans',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: 700,
                    letterSpacing: '0.14px',
                  }}
                >
                  {t('Disable API Access')}
                </Typography>

                <Typography
                  sx={{
                    marginY: '24px',
                    fontFamily: 'OpenSans',
                    fontSize: '15px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    letterSpacing: '0.14px',
                  }}
                >
                  {t(
                    'Are you sure you want to disable Order API access for this seller? This will revoke their API access.',
                  )}
                </Typography>
              </Box>
            </Modal>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size={100} />
          </Box>
        )}
      </Box>
    </Box>
  );
});
