import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

interface EditableDropdownProps {
  field: {
    name: string;
    label: string;
    value: string;
    options: { id: string; label: string }[];
  };
  onChange: (event: any) => void;
}

export const EditableDropdown = ({
  field,
  onChange,
}: EditableDropdownProps) => {
  return (
    <FormControl fullWidth sx={{ marginY: '16px' }}>
      <InputLabel>{field.label}</InputLabel>
      <Select
        label={field.label}
        name={field.name}
        value={field.value}
        onChange={onChange}
      >
        {field.options?.map(({ id, label }) => (
          <MenuItem key={label} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
