import { ContentCopy } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface CopyableInputProps {
  label: string;
  value: string;
  maxWidth?: string;
  disableCopy?: boolean;
  loading?: boolean;
  placeholder?: string;
}

const CopyableInput = ({
  label,
  value,
  maxWidth,
  disableCopy = false,
  loading = false,
  placeholder,
}: CopyableInputProps) => {
  const { t } = useTranslation();

  return (
    <Box
      marginBottom="30px"
      sx={{
        position: 'relative',
        maxWidth: maxWidth,
        flexGrow: 1,
        '&:hover': {
          '& .MuiIconButton-root': {
            opacity: value ? 1 : 0,
          },
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '13px',
          lineHeight: '18px',
          letterSpacing: '0.25px',
          color: 'rgba(0, 0, 0, 0.85)',
          marginBottom: '12px',
        }}
      >
        {label}
      </Typography>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="56px"
          sx={{
            maxWidth: maxWidth,
          }}
        />
      ) : (
        <>
          <TextField
            value={value}
            disabled={true}
            placeholder={placeholder}
            fullWidth
            sx={{
              maxWidth: maxWidth,
              background: 'rgba(233, 232, 232, 0.3)',
              border: 'none',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
          {!disableCopy && (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(value);
                toast.success(t(`${label} copied`));
              }}
              sx={{
                position: 'absolute',
                right: '14px',
                bottom: '8px',
                opacity: 0,
                transition: 'opacity 0.2s ease',
              }}
            >
              <ContentCopy sx={{ color: '#3F6EB7' }} />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export { CopyableInput };
