import {
  ApartmentOutlined,
  CodeOutlined,
  HelpOutlineOutlined,
  MapsHomeWork,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SellersService } from 'app/providers/api';
import {
  getRouteCompanySellerPage,
  getRouteHome,
  getRouteSellerIntegrationsOverviewPage,
  getRouteSellerIntegrationsPage,
  getRouteSellerPage,
} from 'shared/constants/routerConst';
import { contentHeight } from 'shared/constants/styles';
import { UserRole } from 'shared/constants/userRole';
import { useApiQuery } from 'shared/hooks/useApiQuery';
import useAuth from 'shared/hooks/useAuth';
import { NavList } from 'shared/ui/NavList';

export const Navbar = () => {
  const [showIntegration, setShowIntegration] = useState(false);
  const { userData, isLoading, invitedSellerId } = useAuth();
  const userRoles: Array<UserRole> = userData?.roles;
  const { t } = useTranslation();

  useApiQuery(
    ['getSeller', invitedSellerId],
    () => SellersService.fetchSeller(invitedSellerId),
    {
      enabled: !isLoading && !!invitedSellerId,
      onSuccess: (data) => {
        setShowIntegration(data.entitlements.isOrderApiEnabled);
      },
    },
  );

  const navBarItemsList = useMemo(
    () => [
      {
        label: t('Sellers'),
        route: getRouteHome(),
        icon: MapsHomeWork,
        roles: [UserRole.REOPS_ADMIN],
      },
      {
        label: t('Company'),
        route: getRouteCompanySellerPage(invitedSellerId),
        parentRoute: getRouteSellerPage(invitedSellerId),
        icon: ApartmentOutlined,
        roles: [UserRole.SELLER_ADMIN, UserRole.SELLER_DEV],
      },
    ],
    [t, invitedSellerId],
  );

  const bottomItemsList = useMemo(
    () => [
      {
        label: t('Integration'),
        route: getRouteSellerIntegrationsOverviewPage(invitedSellerId),
        parentRoute: getRouteSellerIntegrationsPage(invitedSellerId),
        icon: CodeOutlined,
        roles: [UserRole.SELLER_ADMIN, UserRole.SELLER_DEV],
        disabled: !showIntegration,
      },
      {
        label: t('Help'),
        href: 'mailto:help@re-ops.co',
        icon: HelpOutlineOutlined,
        roles: [
          UserRole.REOPS_ADMIN,
          UserRole.SELLER_ADMIN,
          UserRole.SELLER_DEV,
        ],
      },
    ],
    [t, invitedSellerId, showIntegration],
  );

  return (
    <>
      <Box
        bgcolor="background.paper"
        height={contentHeight}
        minWidth="200px"
        maxWidth="260px"
        position="fixed"
        zIndex="drawer"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NavList
          items={navBarItemsList}
          userRoles={userRoles}
          authIsLoading={isLoading}
        ></NavList>

        <NavList
          items={bottomItemsList}
          userRoles={userRoles}
          authIsLoading={isLoading}
        ></NavList>
      </Box>
      <Box height={contentHeight} minWidth="200px" maxWidth="260px" />
    </>
  );
};
