import { Box, List, Skeleton } from '@mui/material';

import { UserRole } from 'shared/constants/userRole';

import { NavItem } from './NavItem';

export interface NavItemModel {
  label: string;
  route?: string;
  href?: string;
  parentRoute?: string;
  icon: React.ComponentType<{ sx: object }>;
  roles: UserRole[];
  disabled?: boolean;
}

interface NavListProps {
  items: Array<NavItemModel>;
  userRoles?: UserRole[];
  authIsLoading?: boolean;
}

export const NavList = ({ items, userRoles, authIsLoading }: NavListProps) => {
  if (authIsLoading) {
    return (
      <List sx={{ pt: '24px' }}>
        <Box display="flex" flexDirection="column" gap="2px">
          {items.map((item) => {
            const key = item.route ? item.route : item.href;
            return <Skeleton key={key} variant="rectangular" height="48px" />;
          })}
        </Box>
      </List>
    );
  }

  return (
    <List sx={{ pt: '24px' }}>
      {items.map((item) => {
        const key = item.route ? item.route : item.href;
        return <NavItem key={key} item={item} userRoles={userRoles} />;
      })}
    </List>
  );
};
