import { Box, Link, ListItem, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';
import { Link as AppLink, matchPath, useLocation } from 'react-router-dom';

import { UserRole } from 'shared/constants/userRole';

import { NavItemModel } from './NavList';

interface NavItemProps {
  item: NavItemModel;
  userRoles: UserRole[];
}

export const NavItem = ({ item, userRoles }: NavItemProps) => {
  const location = useLocation();

  const isAppLink = !!item.route;
  const activeRoute = item.parentRoute ? item.parentRoute : item.route;
  const isActive = matchPath(activeRoute + '/*', location.pathname);

  const isHidden =
    !userRoles.some((role: UserRole) => item.roles.includes(role)) ||
    item.disabled;

  const Icon = item.icon;

  const disabledClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
  };

  const navLinkColor = isActive ? 'common.white' : 'action.active';

  if (isHidden) {
    return null;
  }

  return (
    <ListItem
      sx={{
        p: 0,
        pl: '24px',
        ':hover': !isActive && {
          bgcolor: 'action.hover',
          cursor: 'pointer',
        },
        bgcolor: isActive && 'primary.main',
        color: isActive && 'common.white',
      }}
    >
      <Box
        component={isAppLink ? AppLink : Link}
        onClick={isActive ? disabledClick : () => {}}
        to={item.route}
        href={item.href}
        display="flex"
        gap="12px"
        alignItems="center"
        p="12px 0"
        width="100%"
        sx={{ textDecoration: 'none' }}
      >
        <Icon
          sx={{
            color: !isAppLink ? 'common.blue' : navLinkColor,
          }}
        />
        <Typography
          fontWeight={!isAppLink ? '600' : ''}
          color={!isAppLink ? 'common.blue' : navLinkColor}
        >
          {item.label}
        </Typography>
      </Box>
    </ListItem>
  );
};
